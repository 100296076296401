import { useEffect } from "react";
import useStore from "./useCart";
import config from "../config";

const API_URL = config.baseURL;

const getCart = async (cart_id, instance = "cart") => {
  const res = await fetch(
    `${API_URL}cart?instance=${instance}&cart_id=${cart_id}`,
    {
      headers: {
        Authorization: `Bearer ${cart_id}`,
        Accept: "application/json",
      },
    }
  );
  const data = await res.json();
  return data?.data;
};

export default function Initialize() {
  const {
    setCartID,
    cart_id,
    setItems,
    setTotal,
    setWishes,
    setTotalWishlist,
    getUser,
    user,
  } = useStore();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (cart_id) return;
    const cartID =
      localStorage.getItem("user_id") || localStorage.getItem("cart_id");
    if (cartID) {
      console.log("Cart found", cartID);
      setCartID(cartID);
      getCart(cartID).then((data) => {
        console.log("Cart data", data);
        setItems(data?.items);
        setTotal(Number(data?.total));
      });
      getCart(cartID, "wishlist").then((data) => {
        console.log("Wishlist data", data);
        setWishes(data?.items);
        setTotalWishlist(Number(data?.total));
      });
    } else {
      if (localStorage.getItem("user_id")) {
        setCartID(localStorage.getItem("user_id"));
      } else {
        const randomString = btoa(Math.random()).slice(0, 16);
        localStorage.setItem("cart_id", randomString);
        setCartID(randomString);
      }
    }
  }, [user]);

  return null;
}
