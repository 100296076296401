import React from "react";
import { FaBarcode, FaCartPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import useStore from "../../store/useCart";

const SwiperItem = ({ item }) => {
  const { addItem, added_to_cart, cart_id, setSnackbar } = useStore();
  return (
    <div style={{ padding: "25px 0" }}>
      <div
        className={` ${window.innerWidth > 425 && "product_details-zoom"}`}
        style={{ borderBottom: "none" }}
      >
        <Link
          to={`/product/${item.slug}`}
          state={{ item }}
          style={{ minHeight: "500px", display: "block" }}
        >
          <div className="rounded overflow-hidden flex flex-col items-center ">
            <div
              style={{
                width: "100%",
                marginLeft: "5px",
                marginBottom: "5px;",
                height: "25px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {item?.sticker ? (
                <img
                  className="h-[40px]"
                  style={{
                    zIndex: 2,
                    position: "absolute",
                    top: "15px",
                  }}
                  src={item && item.sticker}
                  alt="nema slike"
                />
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "180px",
              }}
            >
              <div
                style={{
                  background: `url(${item && item.image})`,
                  height: "100%",
                  backgroundPosition: "center !important",
                  backgroundSize: "contain !important",
                  width: "200px",
                  backgroundRepeat: "no-repeat !important",
                }}
                className="max-h-[180px] swiper-item-size "
                src={item && item.image}
                alt="nema slike"
              />
            </div>
            <div
              className=" py-4 swiper-product-item"
              style={{ width: "100%" }}
            >
              <p className="product_name">{item.name}</p>
              <p
                className="info_values flex content-center	items-center"
                style={{ color: "#777676" }}
              >
                {window.innerWidth > 420 ? (
                  <FaBarcode style={{ marginRight: "10px" }} />
                ) : null}
                {item?.barcode}
              </p>{" "}
              <p className="info_values">
                Neto masa {item?.mass} {item?.massunitname}
              </p>
              <p className="info_values">
                {item?.unit_price} RSD po {item?.massunitname}
              </p>
              <p className="info_values">{item?.webprice}</p>
              {item?.sticker && item?.web_price ? (
                <p className="">
                  Stara cena:{" "}
                  {item?.web_price
                    ? parseFloat(item.web_price).toFixed(2)
                    : null}{" "}
                  RSD
                </p>
              ) : null}
              <p className="price">{item.price} RSD</p>
              {/* <p className="save_money">
                Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD
              </p> */}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSnackbar("Proizvod je dodat u korpu");
                  addItem(cart_id, item, 1);
                }}
                className="add_to_cart text-sm sm:text-base text-nowrap"
              >
                <FaCartPlus className="hidden sm:flex" />
                Dodaj u korpu
              </button>
              <div
                style={{
                  width: "100%",
                  marginRight: "5px",
                  marginBottom: "5px;",
                  height: "25px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {item?.bottom_sticker ? (
                  <img
                    className="w-[100%]"
                    src={item && item.bottom_sticker}
                    alt="nema slike"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SwiperItem;
