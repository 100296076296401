import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Link } from "react-router-dom";

export default function CustomizedAccordions({
  payment_method,
  setPaymentMethod,
}) {
  const handleChange = (method) => (event, newValue) => {
    if (method !== payment_method) setPaymentMethod(newValue ? method : false);
  };

  return (
    <div className="customized_accordions">
      <FormControlLabel
        checked={payment_method === "upon_delivery"}
        onChange={handleChange("upon_delivery")}
        control={<Radio color="primary" />}
        label="Plaćanje prilikom preuzimanja"
      />
      <FormControlLabel
        checked={payment_method === "current_account"}
        onChange={handleChange("current_account")}
        control={<Radio color="primary" />}
        label="Uplatom na tekući račun"
      />
    </div>
  );
}
