import { Field, Form, Formik } from "formik";

import Yup from "./validationts";
import FormikField from "./FormikField";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CustomizedAccordions from "./Accordion";
import useStore from "../../store/useCart";
import axios from "axios";
import config from "../../config";
import { FaSpinner } from "react-icons/fa";
import { useState } from "react";

export default function OrderForm() {
  const { cart_id, user, total, setSnackbar } = useStore();

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (values) => {
    setSubmitted(true);
    axios
      .post(config.baseURL + "orders", {
        ...values,
        cart_id,
        customer_id: user?.id,
      })
      .then((res) => {
        setSubmitted(false);
        console.log(
          "RESPONSE FROM ORDER API: ",
          res,
          res?.data?.data?.order?.id
        );
        if (res?.data?.data?.order?.id) {
          window.location.href = "/uspesna-kupovina";
        } else {
          alert(
            "Došlo je do greške prilikom narudžbine. Molimo vas pokušajte ponovo ili nas kontaktirajte."
          );
        }
      })
      .catch((err) => {
        setSnackbar(
          "Došlo je do greške prilikom narudžbine. Molimo vas pokušajte ponovo ili nas kontaktirajte."
        );
        console.log("ERROR FROM ORDER API: ", JSON.stringify(err));
        setSubmitted(false);
      });
  };
  return (
    <div className="w-full mt-10 ">
      <Formik
        initialValues={{
          name: "",
          last_name: "",
          company_name: "",
          company_pib: "",
          address: "",
          city: "",
          post_code: "",
          house_number: "",
          apartment: "",
          phone_number: "",
          email: "",
          note: "",
          terms: false,
          payment_method: "upon_delivery",
          promocode: "",
        }}
        validationSchema={paymentSchema}
        validateOnBlur={false}
        validateOnChange={false}
        isInitialValid={paymentSchema.isValidSync({
          name: "",
          last_name: "",
          company_name: "",
          company_pib: "",
          address: "",
          city: "",
          post_code: "",
          house_number: "",
          apartment: "",
          phone_number: "",
          email: "",
          note: "",
          terms: false,
          payment_method: "upon_delivery",
          promocode: "",
        })}
        onSubmit={(values, { setErrors }) => {
          console.log("INSIDE PAYMENT FORM SUBMITING!!!", { values });
          handleSubmit(values, setErrors);
        }}
      >
        {({ errors, touched, values, setFieldValue, isValid }) => {
          console.log("CHECKING VALUES!! VALUES ARE: ", values);
          function normalizeWord(word) {
            const map = {
              č: "c",
              ć: "c",
              ž: "z",
              š: "s",
              đ: "dj",
              dž: "dz",
            };

            return word
              .toLowerCase()
              .split("")
              .map((character) => map[character] || character)
              .join("");
          }
          const handleChangeHouseNumber = (event) => {
            const { value } = event.target;
            if (!/^\d+$/.test(value)) {
              errors.house_number =
                "Ako kućni broj sadrži slovo, unesite ga u naredno polje.";
            } else {
              errors.house_number = "";
            }

            if (value === "") {
              errors.house_number = "";
            }

            setFieldValue("house_number", value.replace(/\D/g, ""));
          };

          return (
            <Form noValidate className="px-5">
              <div className="grid grid-cols-2 gap-5">
                <FormikField
                  type="text"
                  label="Ime *"
                  name="name"
                  errors={errors}
                  touched={touched}
                />
                <FormikField
                  type="text"
                  label="Prezime *"
                  name="last_name"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="grid grid-cols-2 mt-5 gap-5">
                <FormikField
                  type="text"
                  label="Naziv kompanije (opciono)"
                  name="company_name"
                  errors={errors}
                  touched={touched}
                />
                <FormikField
                  type="number"
                  label="PIB (opciono)"
                  name="company_pib"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="grid grid-cols-2 mt-5 gap-5">
                <FormikField
                  type="text"
                  label="Poštanski broj *"
                  name="post_code"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <br />
              <b className="text-xl">Podaci o isporuci</b>
              <br />
              <div className="grid grid-cols-2 mt-3 gap-5">
                <FormikField
                  type="text"
                  label="Ulica *"
                  name="address"
                  errors={errors}
                  touched={touched}
                />
                <FormikField
                  type="text"
                  label="Grad *"
                  name="city"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="grid grid-cols-2 mt-5 gap-5">
                <FormikField
                  type="text"
                  label="Kućni broj *"
                  name="house_number"
                  errors={errors}
                  touched={touched}
                />
                <FormikField
                  type="text"
                  label="Broj stana ili ulaza (opciono)"
                  name="apartment"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className="grid grid-cols-2 mt-5 gap-5">
                <FormikField
                  type="number"
                  label="Telefon *"
                  name="phone_number"
                  errors={errors}
                  touched={touched}
                />
                <FormikField
                  type="email"
                  label="Adresa e-pošte *"
                  name="email"
                  errors={errors}
                  touched={touched}
                />
              </div>
              <br />
              <b className="text-lg">Način plaćanja</b>
              <br />
              <CustomizedAccordions
                payment_method={values.payment_method}
                setPaymentMethod={(method) =>
                  setFieldValue("payment_method", method)
                }
              />
              <br />
              <b className="text-lg">Dodatne informacije</b>
              <br />
              <div className=" w-full flex flex-col">
                <style jsx>{`
                  textarea {
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    min-height: 100px;
                    margin-top: 5px;
                    padding: 10px;
                  }
                `}</style>
                <label className="mt-5">Napomene o narudžbini (opciono)</label>
                <Field
                  component="textarea"
                  name="note"
                  placeholder="Napomene o narudžbini, npr. posebne napomene za isporuku."
                />
              </div>
              <p className="error_msg">{errors.terms}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="terms"
                    color="primary"
                    value={values.terms}
                    onChange={() => setFieldValue("terms", !values.terms)}
                  />
                }
                label={
                  <p className="accept ">
                    Pročitao/la sam i prihvatam{" "}
                    <a className="text-green-500" href={"/uslovi"}>
                      uslove
                    </a>{" "}
                    web sajta. *
                  </p>
                }
              />{" "}
              {errors && errors["terms"] && (
                <p className="text-red-500">{errors["terms"]}</p>
              )}
              <br />
              <button
                className="custom_button w-full my-5 bg-orange-400 text-white flex justify-center items-center font-bold py-2 rounded-md"
                type="submit"
              >
                {!submitted ? (
                  "Naruči"
                ) : (
                  <FaSpinner className="animate-spin mx-auto" />
                )}
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export const paymentSchema = Yup.object().shape({
  payment_method: Yup.string().required("Polje je obavezno"),
  terms: Yup.bool().oneOf([true], "Molimo vas da prihvatite uslove web sajta"),
  name: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(255, "Polje može sadržati najviše 255 karaktera")
    .required("Polje je obavezno"),
  last_name: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(255, "Polje može sadržati najviše 255 karaktera")
    .required("Polje je obavezno"),
  phone_number: Yup.number()
    .max(99999999999999999999, "Polje može sadržati najviše 20 karaktera")
    .required("Obavezan format 0601234567"),
  email: Yup.string()
    .email("Molim Vas da unesete mail u ispravnom obliku npr: mail@domen.com")
    .max(255, "Polje može sadržati najviše 255 karaktera")
    .required("Email je obavezan"),
  city: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(255, "Polje može sadržati najviše 255 karaktera")
    .required("Polje je obavezno"),
  post_code: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(50, "Polje može sadržati najviše 50 karaktera")
    .required("Polje je obavezno"),
  address: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(50, "Polje može sadržati najviše 50 karaktera")
    .required("Polje je obavezno"),
  house_number: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .required("Polje je obavezno"),
  apartment: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(8, "Polje može sadržati najviše 8 karaktera"),
  company_name: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(255, "Polje može sadržati najviše 255 karaktera"),
  company_pib: Yup.number().min(1, "Polje mora sadržati najmanje 1 karakter"),
  note: Yup.string()
    .min(1, "Polje mora sadržati najmanje 1 karakter")
    .max(1200, "Polje može sadržati najviše 1200 karaktera"),
});
