import { ChevronRight, ShoppingCart } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import useStore from "../../store/useCart";
import { BsX } from "react-icons/bs";

export default function CartPreview() {
  const [open, setOpen] = useState(false);
  const { items, removeItems, cart_id, setSnackbar } = useStore();
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isMobile = window.innerWidth < 1024;

  return (
    <div className="relative">
      {items?.length ? (
        <div className="absolute top-0 right-0 w-2 h-2 translate-x-1/2 bg-[#a6ce38] rounded-full"></div>
      ) : null}
      {isMobile ? (
        <a href={items?.length ? "/korpa" : ""}>
          <ShoppingCart className=" cursor-pointer" />
        </a>
      ) : (
        <div
          onClick={() => {
            setOpen(!open);
            if (!items?.length) window.location.href = "/korpa";
          }}
          className="flex cursor-pointer items-center gap-2"
        >
          <ShoppingCart className=" cursor-pointer" />
          <span className="hidden sm:flex">Korpa</span>
        </div>
      )}
      {open && items?.length ? (
        <div
          ref={ref}
          className="absolute bottom-0 w-[400px]  bg-white shadow-md rounded-md translate-y-[105%] border left-1/2 -translate-x-1/2"
        >
          <div className="w-full max-h-[400px] overflow-y-scroll">
            {items?.map((item) => {
              return (
                <a
                  href={"/product/" + item.product.slug}
                  className="grid grid-cols-5 relative border-b"
                >
                  <BsX
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeItems(cart_id, item.product);
                    }}
                    className="absolute right-0 top-0  w-7 h-7 text-gray-400 cursor-pointer"
                  />
                  <div>
                    <img
                      className="w-full aspect-[1] object-contain p-1"
                      src={item.product.image}
                      alt={item.product.name}
                    />
                  </div>
                  <div className="col-span-2 line-clamp-2  flex hfu items-center">
                    {item.product.name}
                  </div>
                  <p className="text-[#a6ce38]  justify-end  pr-5 col-span-2 flex items-center font-bold text-nowrap tracking-wide">
                    {parseFloat(item.quantity * item.product.price).toFixed(2)}{" "}
                    RSD
                  </p>
                </a>
              );
            })}
          </div>
          <a
            href="/korpa"
            className="w-full border-t  h-12 gap-2 flex justify-center items-center"
          >
            Završi kupovinu <ChevronRight />
          </a>
        </div>
      ) : null}
    </div>
  );
}
