import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, A11y } from "swiper";
import icon from "../../assets/icons/Ellipse.png";
// import SwiperCore, { Autoplay } from "swiper/core";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperItem from "./SwiperItem";
import "./SwiperProducts.scss";
// SwiperCore.use([Autoplay]);

const SwiperProducts = ({ data }) => {
  const isMobile = window.innerWidth < 768;

  return (
    <div
      className="swiper_produt"
      id={data.baners.length ? "swiper-products" : ""}
    >
      {!data.baners.length ? (
        <div className="name-wrapper">
          <hr />
          <p className="swiper_product_name flex justify-start items-center">
            <img className="mr-2" src={icon} alt={data.name} />
            {data.name}
            <img className="ml-2" src={icon} alt={data.name} />
          </p>
          <hr />
        </div>
      ) : null}
      <Swiper
        id="custom-slider-products"
        className="mt-10 custom-slider"
        style={{ display: "flex", flexDirection: "row-reverse" }}
        modules={[Navigation, Scrollbar, A11y]}
        navigation={!data.baners.length}
        spaceBetween={0}
        slidesPerView={4}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          426: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
      >
        {data.baners && data.baners.length
          ? data.baners.map((item, index) => {
              return (
                <img
                  className="baner-image"
                  key={index}
                  src={isMobile ? item.mobile_image || item.image : item.image}
                  alt={item.order}
                />
              );
            })
          : null}
        {data.products &&
          data.products.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <SwiperItem item={item} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default SwiperProducts;
