import React, { useState, useEffect } from "react";
import "../../../cms/components/AllPages/AllPages.scss";
import { getApiActiveProducts } from "../../HTTP/api";
import Magnifier from "./components/Magnifier";
import "./styles.scss";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FaBarcode, FaCartPlus } from "react-icons/fa";
import useStore from "../../../store/useCart";

const VisibleOnsite = () => {
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [meta, setMeta] = useState(null);

  const apiCall = async (page = 1) => {
    const { data, meta } = await getApiActiveProducts(search, { page });
    setData(data);
    setMeta(meta);
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    if (event === "click" || event.key === "Enter") {
      apiCall();
    }
  };
  const handleOnChange = (e) => setSearch(e.target.value);

  const { addItem, added_to_cart, cart_id, setSnackbar } = useStore();
  return (
    <div className="all_content" id="visibleOnSite">
      <div className="input-wrapper">
        <input
          value={search}
          onChange={handleOnChange}
          className="search"
          placeholder="Pretraga proizvoda po nazivu"
          onKeyPress={(e) => handleSearch(e)}
        />
        <span onClick={() => handleSearch("click")}>
          <Magnifier />
        </span>
      </div>
      <div className="nav_menu_proizvodi grid grid-cols-6 bg-[#e7e3e3] h-[50px] items-center">
        <div>
          <p>Barcode</p>
        </div>
        <div>
          <p>Ime</p>
        </div>
        <div>
          <p>Cena</p>
        </div>
      </div>
      <div className="grid grid-cols-1">
        {data &&
          data.map((item, index) => {
            return (
              <div className="proizvodi grid grid-cols-6 py-3" key={index}>
                <div>
                  <p
                    className="info_values flex content-center	items-center"
                    style={{ color: "#777676" }}
                  >
                    {window.innerWidth > 420 ? (
                      <FaBarcode style={{ marginRight: "10px" }} />
                    ) : null}
                    {item?.barcode}
                  </p>{" "}
                </div>
                <div>
                  <p>{item.name}</p>
                </div>
                <div>
                  <p>{parseFloat(item.price).toFixed(2)} RSD</p>
                  {item?.sticker && item?.web_price ? (
                    <p className="">
                      Stara cena:{" "}
                      {item?.web_price
                        ? parseFloat(item.web_price).toFixed(2)
                        : null}{" "}
                      RSD
                    </p>
                  ) : null}
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSnackbar("Proizvod je dodat u korpu");
                    addItem(cart_id, item, 1);
                  }}
                  className="add_to_cart"
                >
                  <FaCartPlus />
                  Dodaj u korpu
                </button>
              </div>
            );
          })}
      </div>
      <div className="pagination-mui">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(meta?.total / meta?.per_page)}
            siblingCount={0}
            onChange={(e, current) => {
              apiCall(current);
            }}
          />
        </Stack>
      </div>
    </div>
  );
};

export default VisibleOnsite;
