import React, { useState } from "react";
import { Field } from "formik";
import { useIntl } from "react-intl";

const FormikField = ({
  type,
  name,
  label,
  errors,
  touched,
  focus = false,
  readOnly = false,
  ...rest
}) => {
  return (
    <div className="input_holder">
      <style jsx>{`
        input {
          border: 1px solid #ccc;
          border-radius: 5px;
          height: 35px;
          padding: 0 10px;
        }
      }`}</style>
      <div className="flex flex-col">
        <label htmlFor={name}>{label}</label>
        <Field
          type={type}
          name={name}
          autoFocus={focus}
          readOnly={readOnly}
          {...rest}
        />
      </div>
      {errors && errors[name] && touched[name] && (
        <p className="text-red-500">{errors[name]}</p>
      )}
    </div>
  );
};

export default FormikField;
