import { FaArrowLeft, FaBox, FaInfo, FaUser } from "react-icons/fa";
import useStore from "../../store/useCart";
import LoginForm from "./LoginForm";
import { useEffect, useState } from "react";
import { ArrowLeft } from "@material-ui/icons";
import { BsX } from "react-icons/bs";

export default function UserProfile() {
  const {
    user,
    user_id,
    getOrders,
    logOff,
    changeInfo,
    changePassword,
    orders,
  } = useStore();
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [error, setError] = useState("");
  const [ordersO, setOrders] = useState(false);
  useEffect(() => {
    getOrders();
  }, [localStorage.getItem("user_id")]);
  const [currentOrder, setCurrentOrder] = useState(null);
  return (
    <div>
      {user?.email ? (
        <div className="max-w-screen-lg grid mx-auto grid-cols-1 py-20 md:grid-cols-2 gap-10">
          <div className="flex flex-col items-center gap-5">
            <div className="flex items-center gap-5 text-xl">
              <FaUser size={50} />
              <h2>{user?.name}</h2>
            </div>
            <h2>{user?.email}</h2>
            <div className="w-full flex justify-center items-center">
              <p
                onClick={() => logOff()}
                className="text-center px-5 py-2 bg-black text-white rounded-md cursor-pointer flex items-center gap-2"
              >
                <FaArrowLeft /> Odjavi se
              </p>
            </div>
            <div className="w-full flex justify-center items-center">
              <p
                onClick={() => {
                  setOrders(!ordersO);
                  setCurrentOrder(null);
                }}
                className="text-center px-5 py-2 text-black bg-white border rounded-md cursor-pointer flex items-center gap-2"
              >
                {ordersO ? (
                  <>
                    <FaUser /> Detalji
                  </>
                ) : (
                  <>
                    <FaBox /> Porudžbine
                  </>
                )}
              </p>
            </div>
          </div>
          {currentOrder ? (
            <div className="w-full flex gap-5 flex-col">
              <p
                onClick={() => {
                  setCurrentOrder(null);
                }}
                className="cursor-pointer flex items-center  font-bold"
              >
                <ArrowLeft /> Nazad
              </p>
              <p className="flex justify-between border-b">
                ID: <b>{currentOrder.id}</b>
              </p>
              <p className="flex justify-between border-b">
                Ukupno: <b>{currentOrder.total.toFixed(2)} RSD</b>
              </p>
              <p className="flex justify-between border-b">
                Cena dostave:{" "}
                <b>{currentOrder.delivery_price.toFixed(2)} RSD</b>
              </p>
              <p className="flex justify-between border-b">
                Ime: <b>{currentOrder.name}</b>
              </p>
              <p className="flex justify-between border-b">
                Prezime: <b>{currentOrder.last_name}</b>
              </p>
              <p className="flex justify-between border-b">
                Poštanski broj: <b>{currentOrder.post_code}</b>
              </p>
              <p className="flex justify-between border-b">
                Adresa: <b>{currentOrder.address}</b>
              </p>
              <p className="flex justify-between border-b">
                Grad: <b>{currentOrder.city}</b>
              </p>
              <p className="flex justify-between border-b">
                Telefon: <b>{currentOrder.phone_number}</b>
              </p>
              <p className="flex justify-between border-b">
                Datum naručivanja:{" "}
                <b>
                  {new Date(currentOrder.created_at).toLocaleDateString(
                    "sr-RS"
                  )}
                </b>
              </p>
              <p className="flex justify-between border-b">
                Dodatne informacije: <b>{currentOrder.note}</b>
              </p>
            </div>
          ) : ordersO ? (
            <div className="grid grid-cols-1 h-fit gap-4">
              <p className="font-bold">Porudžbine</p>
              {orders?.map((order) => {
                return (
                  <div className="flex items-center gap-4 justify-between">
                    <p className="flex items-center gap-5">
                      <p>
                        ID <b>{order?.id}</b>,
                      </p>
                      {new Date(order?.created_at).toLocaleDateString("sr-RS")}
                    </p>
                    <p className="text-green-700 flex gap-5 items-center font-bold">
                      {order?.total?.toFixed(2)} RSD
                      <div
                        onClick={() => setCurrentOrder(order)}
                        className="h-full border border-black  cursor-pointer rounded-md aspect-square  w-7 flex justify-center items-center text-black"
                      >
                        <FaInfo />
                      </div>
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {error && (
                <p className="bg-red-500 text-white px-5 py-2">{error}</p>
              )}
              <b>Izmena profila</b>
              <input
                type="text"
                placeholder={user?.name}
                value={name}
                className="border rounded-md  px-5 py-1"
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder={user?.email}
                value={email}
                className="border rounded-md  px-5 py-1"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={() =>
                  changeInfo(name || user?.name, email || user?.email)
                }
                className="px-5 py-2 bg-black text-white rounded-md cursor-pointer"
              >
                Sačuvaj
              </button>
              <br />

              <b>Izmena lozinke</b>
              <input
                type="password"
                placeholder="Trenutna lozinka"
                value={currentPassword}
                className="border rounded-md  px-5 py-1"
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Nova lozinka"
                value={newPassword}
                className="border rounded-md  px-5 py-1"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Ponovite novu lozinku"
                value={newPasswordRepeat}
                className="border rounded-md  px-5 py-1"
                onChange={(e) => setNewPasswordRepeat(e.target.value)}
              />
              <button
                onClick={() => {
                  setError("");
                  if (newPassword === newPasswordRepeat) {
                    changePassword(
                      currentPassword,
                      newPassword,
                      newPasswordRepeat
                    );
                  } else {
                    setError("Unete lozinke se ne poklapaju");
                  }
                }}
                className="px-5 py-2 bg-black text-white rounded-md cursor-pointer"
              >
                Sačuvaj
              </button>
            </div>
          )}
        </div>
      ) : (
        <>
          <LoginForm />
        </>
      )}
    </div>
  );
}
