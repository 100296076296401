import { useState } from "react";
import useStore from "../../store/useCart";

export default function LoginForm() {
  const { login, register } = useStore();
  const [registerO, setRegister] = useState(false);
  const [forgetPassO, setForgetPass] = useState(false);
  const [error, setError] = useState("");
  const handleLogin = (e) => {
    e.preventDefault();
    login(e.target[0].value, e.target[1].value);
  };
  const handleRegister = (e) => {
    e.preventDefault();

    const email = e.target[1].value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError("Unesite validan email");
      return;
    }

    register(e.target[0].value, email, e.target[2].value, e.target[3].value);
  };
  return registerO ? (
    <div className="w-full flex justify-center items-center flex-col px-5 2xl:px-0 py-20">
      <h1 className="text-2xl font-bold mb-5">Registruj se</h1>
      {error && <p className="text-red-500 text-sm">{error}</p>}
      <form
        onSubmit={handleRegister}
        className="grid grid-cols-1 max-w-[500px] w-full gap-4"
      >
        <input
          type="text"
          className="border rounded-md border-black px-5 py-2"
          placeholder="Ime"
        />
        <input
          type="text"
          className="border rounded-md border-black px-5 py-2"
          placeholder="Email"
        />
        <input
          className="border rounded-md border-black px-5 py-2"
          type="password"
          placeholder="Šifra"
        />
        <input
          className="border rounded-md border-black px-5 py-2"
          type="password"
          placeholder="Šifra - ponovo"
        />
        <button
          className="text-white bg-black  px-5 py-2 rounded-md"
          type="submit"
        >
          Registruj se
        </button>
        <p
          onClick={() => {
            setRegister(false);
          }}
          className="cursor-pointer "
        >
          Ili se <b className="underline">Uloguj</b>.
        </p>
      </form>
    </div>
  ) : (
    <div className="w-full flex justify-center items-center flex-col px-5 2xl:px-0 py-20">
      <h1 className="text-2xl font-bold mb-5">Uloguj se</h1>
      <form
        onSubmit={handleLogin}
        className="grid grid-cols-1 max-w-[500px] w-full gap-4"
      >
        <input
          type="text"
          className="border rounded-md border-black px-5 py-2"
          placeholder="Email"
        />
        <input
          className="border rounded-md border-black px-5 py-2"
          type="password"
          placeholder="Šifra"
        />
        <button
          className="text-white bg-black  px-5 py-2 rounded-md"
          type="submit"
        >
          Uloguj se
        </button>
        <p
          onClick={() => {
            setRegister(true);
          }}
          className="cursor-pointer "
        >
          Ili se <b className="underline">Registruj</b>.
        </p>
      </form>
    </div>
  );
}
