import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { GlobalProvider } from "./components/GlobalContext/GlobalContext";
import { BanerProvider } from "./pages/Homepage/useBaner";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalProvider>
    <BanerProvider>
      <App />
    </BanerProvider>
  </GlobalProvider>
);
