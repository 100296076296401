import React from "react";
import img from "../../assets/icons/as-logo.png";
import {} from "react-icons/go";
import { FaFacebookF, FaHeart, FaUser } from "react-icons/fa";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import "./Header.scss";
import { Link } from "react-router-dom";
import routes from "../../routes";
import SearchBar from "./Search";
import { useState } from "react";
import CartPreview from "./Cart";
import { Star } from "@material-ui/icons";
import useStore from "../../store/useCart";

const TopHeader = ({ handleShow }) => {
  const [sctollTop, setScrollTop] = useState(0);

  const { user } = useStore();

  window.addEventListener("scroll", (event) => {
    setScrollTop(document.querySelector("html").scrollTop);
    if (document.querySelector("html").scrollTop >= 240) {
      document
        .querySelector(".full-header")
        ?.classList.add("full-header-margin");
    } else {
      document
        .querySelector(".full-header")
        ?.classList.remove("full-header-margin");
    }
  });

  return (
    <div
      className={`grid grid-cols-3 md:grid-cols-5 mt-3 ${
        sctollTop >= 240 && "add-transition"
      }`}
      style={
        sctollTop >= 240
          ? {
              padding: " 0 10px",
            }
          : null
      }
    >
      <div className="as_logo order-1 md:order-1 flex items-center justify-center">
        <Link to={routes.HOME}>
          <img
            src={img}
            alt="as-logo"
            className={
              sctollTop >= 240 ? "img-smaller add-transition" : "img-full-size"
            }
          />{" "}
        </Link>
      </div>
      <div className="mt-10 col-span-3 order-3 md:order-2 flex justify-center items-center w-[100%]">
        <div className="flex items-center w-full justify-center flex-row ">
          <SearchBar
            handleShow={handleShow}
            placeholder="Pretrazite proizvode..."
          />
          <div className="flex-grow flex h-full pt-5 gap-5 sm:gap-2 2xl:gap-5 sm:pt-0 pb-2 md:pb-0 flex-row  px-5 w-[50%]">
            <a
              href="/lista-zelja"
              className="flex gap-2 items-center line-clamp-1"
            >
              <FaHeart />{" "}
              <span className="hidden lg:flex line-clamp-1">Lista želja</span>
            </a>
            <CartPreview />
            <a href="/profile" className="flex gap-2 items-center">
              <FaUser />{" "}
              <span className="hidden lg:flex">
                {user?.email ? user?.name?.split(" ")?.[0] : "Prijava"}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="right_side order-2 col-span-2 md:order-3 md:col-span-1 flex items-center justify-end">
        <div className="icons flex justify-end">
          <a
            href="https://www.facebook.com/AsTrgovina"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
          {/* <a href=""></a> */}
          <BsInstagram />
          <BsYoutube />
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
