import { BsX } from "react-icons/bs";
import useStore from "../../store/useCart";
import OrderForm from "./OrderForm";
import { FaShoppingCart } from "react-icons/fa";

export default function CartPage() {
  const { items, removeItems, removeItem, cart_id, addItem, total } =
    useStore();

  return items?.length ? (
    <section className="w-full grid grid-cols-1 lg:grid-cols-2 py-10">
      <div className="w-full max-h-[500px] lg:max-h-[700px] overflow-y-scroll flex flex-col gap-4">
        {items?.map((item) => {
          return (
            <a
              href={"/product/" + item.product.slug}
              className="grid grid-cols-5 relative border-b"
            >
              <BsX
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeItems(cart_id, item.product);
                }}
                className="absolute right-0 top-0  w-7 h-7 text-gray-400 cursor-pointer"
              />
              <div>
                <img
                  className="w-full aspect-[1] object-contain p-1"
                  src={item.product.image}
                  alt={item.product.name}
                />
              </div>
              <div className="col-span-2 line-clamp-2  flex  flex-col justify-center">
                {item.product.name}
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="grid grid-cols-3 h-10 w-[120px]"
                >
                  <div
                    onClick={() => {
                      removeItem(cart_id, item.product, item.quantity - 1);
                    }}
                    className="text-xl flex justify-center items-center border"
                  >
                    -
                  </div>
                  <div className="text-xl flex justify-center items-center border">
                    {item.quantity}
                  </div>
                  <div
                    onClick={() => {
                      addItem(cart_id, item.product, 1);
                    }}
                    className="text-xl flex justify-center items-center border"
                  >
                    +
                  </div>
                </div>
              </div>
              <p className="text-[#a6ce38] text-xl  justify-end  pr-5 col-span-2 flex items-center font-bold text-nowrap tracking-wide">
                {parseFloat(item.quantity * item.product.price).toFixed(2)} RSD
              </p>
            </a>
          );
        })}
        <p className="flex justify-between w-full border-b mb-10 mx-auto text-2xl">
          <span>
            <b>Ukupno</b>
          </span>
          {total} RSD
        </p>
      </div>
      <OrderForm handleSubmit={(e) => console.log(e)} />
    </section>
  ) : (
    <div className="w-full min-h-[70vh] flex flex-col gap-10 justify-center items-center">
      <FaShoppingCart className="text-9xl text-[#a6ce38]" />
      <p className="text-4xl text-[#a6ce38]">Korpa je prazna</p>
    </div>
  );
}
