import { BsCheck2Circle } from "react-icons/bs";

export default function UspesnaKupovina() {
  return (
    <div className="w-full min-h-[50vh] flex justify-center flex-col py-20 items-center">
      <BsCheck2Circle className="text-[#a6ce38] text-9xl" />
      <h3 className="text-4xl text-[#a6ce38]">
        VAŠA PORUDŽBINA JE USPEŠNO PRIMLJENA!
      </h3>
      <br />
      <p style={{ maxWidth: "700px" }}>
        Vaša porudžbina je uspešno primljena i trenutno je u obradi. <br />
        <br />
        Hvala vam što ste izabrali <b>AS Trgovinu</b> za vašu online kupovinu!
        Cenimo vaše poverenje i drago nam je što smo vam mogli omogućiti
        jednostavno i sigurno iskustvo kupovine putem naše internet prodavnice.
        <br />
        <br />
        Ne zaboravite da redovno posećujete našu internet prodavnicu kako biste
        uvek bili u toku s našim najnovijim proizvodima i specijalnim ponudama.
        <br />
        <br />
        Ako imate bilo kakva pitanja ili su vam potrebne dodatne informacije,
        slobodno nas kontaktirajte putem telefona ili emaila:
        <br />
        <br />
        <b>Telefon</b>: 018 551 511 | 018 551 950
        <br />
        <b>Email</b>: info@astrgovina.rs
        <br />
        <br />
        Srdačan pozdrav,
        <br />
        Vaša AS Trgovina
      </p>
      <br />
      <a href="/" className="bg-[#a6ce38] text-white px-5 py-2 rounded-md">
        Vrati se na početnu
      </a>
    </div>
  );
}
