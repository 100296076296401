import React from "react";
import { FaBarcode, FaCartPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import icon from "../../assets/icons/Ellipse.png";
import "./styles.scss";
import { Scrollbar, Navigation, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import useStore from "../../store/useCart";
import Message from "../../cms/components/Message";
import { Snackbar } from "@material-ui/core";
const ShowProducts = ({ data }) => {
  const { addItem, added_to_cart, cart_id, setSnackbar } = useStore();
  return (
    <div className="mt-12">
      <Snackbar
        open={added_to_cart}
        autoHideDuration={6000}
        onClose={() => {}}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message="Note archived"
      />
      <div className="connected_products">
        {/* <p className="swiper_product_name flex justify-start items-center">
          <img className="mr-2" src={icon} alt="ikonica" />
          Povezani proizvodi
        </p> */}
        <div className="name-wrapper">
          <hr />
          <p className="swiper_product_name flex justify-start items-center">
            <img className="mr-2" src={icon} alt="Povezani proizvodi" />
            Povezani proizvodi
            <img className="ml-2" src={icon} alt="Povezani proizvodi" />
          </p>
          <hr />
        </div>
        <Swiper
          id="custom-slider-products"
          className="mt-10 custom-slider"
          style={{ display: "flex", flexDirection: "row-reverse" }}
          modules={[Navigation, Scrollbar, A11y]}
          navigation
          spaceBetween={0}
          slidesPerView={5}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            426: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {/* <div className="flex mt-10 flex-wrap	"> */}
          {data?.connectedProducts &&
            data?.connectedProducts?.map((item, index) => {
              return (
                <SwiperSlide
                  style={{ transform: "none" }}
                  className="show-product-connected"
                  key={index}
                >
                  <div>
                    <div
                      className={` ${
                        window.innerWidth > 425 && "product_details-zoom"
                      }`}
                      style={{ borderBottom: "none" }}
                    >
                      <Link
                        to={`/product/${item.slug}`}
                        state={{ item }}
                        style={{ minHeight: "500px", display: "block" }}
                      >
                        <div className="rounded overflow-hidden flex flex-col items-center ">
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "5px",
                              marginBottom: "5px;",
                              height: "25px",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            {item?.sticker ? (
                              <img
                                className="h-[40px]"
                                style={{
                                  zIndex: 2,
                                  position: "absolute",
                                  top: "15px",
                                }}
                                src={item && item.sticker}
                                alt="nema slike"
                              />
                            ) : null}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "180px",
                            }}
                          >
                            <div
                              style={{
                                background: `url(${item && item.image})`,
                                height: "100%",
                                backgroundPosition: "center !important",
                                backgroundSize: "contain !important",
                                width: "200px",
                                backgroundRepeat: "no-repeat !important",
                              }}
                              className="max-h-[180px] swiper-item-size "
                              src={item && item.image}
                              alt="nema slike"
                            />
                          </div>
                          <div
                            className=" py-4 swiper-product-item"
                            style={{ width: "100%" }}
                          >
                            <p className="product_name  line-clamp-1">
                              {item.name}
                            </p>
                            <p
                              className="info_values flex content-center	items-center"
                              style={{ color: "#777676" }}
                            >
                              {window.innerWidth > 420 ? (
                                <FaBarcode style={{ marginRight: "10px" }} />
                              ) : null}
                              {item?.barcode}
                            </p>{" "}
                            <p className="info_values">
                              Neto masa {item?.mass} {item?.massunitname}
                            </p>
                            <p className="info_values">
                              {item?.unit_price} RSD po {item?.massunitname}
                            </p>
                            <p className="info_values">{item?.webprice}</p>
                            {item?.sticker && item?.web_price ? (
                              <p className="">
                                Stara cena:{" "}
                                {item?.web_price
                                  ? parseFloat(item.web_price).toFixed(2)
                                  : null}{" "}
                                RSD
                              </p>
                            ) : (
                              <br />
                            )}
                            <p className="price">{item.price} RSD</p>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSnackbar("Proizvod je dodat u korpu");
                                addItem(cart_id, item, 1);
                              }}
                              className="add_to_cart text-sm sm:text-base"
                            >
                              <FaCartPlus className="hidden sm:flex" />
                              Dodaj u korpu
                            </button>
                            <div
                              style={{
                                width: "100%",
                                marginRight: "5px",
                                marginBottom: "5px;",
                                height: "25px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {item?.bottom_sticker ? (
                                <img
                                  className="w-[100%]"
                                  src={item && item.bottom_sticker}
                                  alt="nema slike"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>{" "}
                </SwiperSlide>
              );
            })}
          {/* </div> */}
        </Swiper>
      </div>

      <div className="connected_products">
        <div className="name-wrapper">
          <hr />
          <p className="swiper_product_name flex justify-start items-center">
            <img className="mr-2" src={icon} alt="Preporučeni proizvodi" />
            Preporučeni proizvodi
            <img className="ml-2" src={icon} alt="Preporučeni proizvodi" />
          </p>
          <hr />
        </div>
        <Swiper
          id="custom-slider-products"
          className="mt-10 custom-slider"
          style={{ display: "flex", flexDirection: "row-reverse" }}
          modules={[Navigation, Scrollbar, A11y]}
          navigation
          spaceBetween={0}
          slidesPerView={4}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            426: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {" "}
          {data?.similarProducts &&
            data.similarProducts.map((item, index) => {
              return (
                <SwiperSlide
                  style={{ transform: "none" }}
                  key={index}
                  className="show-product-connected"
                >
                  <div>
                    <div
                      className={` ${
                        window.innerWidth > 425 && "product_details-zoom"
                      }`}
                      style={{ borderBottom: "none" }}
                    >
                      <Link
                        to={`/product/${item.slug}`}
                        state={{ item }}
                        style={{ minHeight: "500px", display: "block" }}
                      >
                        <div className="rounded overflow-hidden flex flex-col items-center ">
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "5px",
                              marginBottom: "5px;",
                              height: "25px",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            {item?.sticker ? (
                              <img
                                className="h-[40px]"
                                style={{
                                  zIndex: 2,
                                  position: "absolute",
                                  top: "15px",
                                }}
                                src={item && item.sticker}
                                alt="nema slike"
                              />
                            ) : null}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "180px",
                            }}
                          >
                            <div
                              style={{
                                background: `url(${item && item.image})`,
                                height: "100%",
                                backgroundPosition: "center !important",
                                backgroundSize: "contain !important",
                                width: "200px",
                                backgroundRepeat: "no-repeat !important",
                              }}
                              className="max-h-[180px] swiper-item-size "
                              src={item && item.image}
                              alt="nema slike"
                            />
                          </div>
                          <div
                            className=" py-4 swiper-product-item"
                            style={{ width: "100%" }}
                          >
                            <p className="product_name line-clamp-1">
                              {item.name}
                            </p>
                            <p
                              className="info_values flex content-center	items-center"
                              style={{ color: "#777676" }}
                            >
                              {window.innerWidth > 420 ? (
                                <FaBarcode style={{ marginRight: "10px" }} />
                              ) : null}
                              {item?.barcode}
                            </p>{" "}
                            <p className="info_values">
                              Neto masa {item?.mass} {item?.massunitname}
                            </p>
                            <p className="info_values">
                              {item?.unit_price} RSD po {item?.massunitname}
                            </p>
                            <p className="info_values">{item?.webprice}</p>
                            {item?.sticker && item?.web_price ? (
                              <p className="">
                                Stara cena:{" "}
                                {item?.web_price
                                  ? parseFloat(item.web_price).toFixed(2)
                                  : null}{" "}
                                RSD
                              </p>
                            ) : (
                              <p className="text-white">. </p>
                            )}
                            <p className="price">{item.price} RSD</p>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSnackbar("Proizvod je dodat u korpu");
                                addItem(cart_id, item, 1);
                              }}
                              className="add_to_cart text-sm sm:text-base"
                            >
                              <FaCartPlus className="hidden sm:flex" />
                              Dodaj u korpu
                            </button>
                            <div
                              style={{
                                width: "100%",
                                marginRight: "5px",
                                marginBottom: "5px;",
                                height: "25px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {item?.bottom_sticker ? (
                                <img
                                  className="w-[100%]"
                                  src={item && item.bottom_sticker}
                                  alt="nema slike"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default ShowProducts;
