import { create } from "zustand";
import config from "../config";
import axios from "axios";

const useStore = create((set) => ({
  cart_id: 0,
  count: 0,
  items: [],
  wishes: [],
  total_wishlist: 0,
  total: 0,
  snackbar: false,
  snackbar_text: "",
  total_delivery_price: 0,
  user: {},
  token: "",
  orders: [],
  setUser: (user) => set({ user }),
  setWishes: (wishes) => set({ wishes }),
  setTotalWishlist: (total_wishlist) => set({ total_wishlist }),
  setSnackbar: (text) => set({ snackbar: true, snackbar_text: text }),
  closeSnackbar: () => set({ snackbar: false }),
  setCartID: (id) => set({ cart_id: id }),
  addItem: async (id, product, quantity, instance = "cart") => {
    console.log(id, product, quantity);
    const formData = new FormData();
    formData.append("instance", instance);
    formData.append("cart_id", id);
    formData.append("product_id", product?.id.toString());
    formData.append("quantity", quantity.toString());

    const res = await fetch(`${config.baseURL}cart/items`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("token")?.slice(1, -1),
        Accept: "application/json",
      },
      body: formData,
    });

    const data = await res.json();

    set((state) =>
      instance === "cart"
        ? {
            items: data?.data?.cart?.items,
            total: Number(data?.data?.cart?.total),
          }
        : {
            wishes: data?.data?.cart?.items,
            total_wishlist: Number(data?.data?.cart?.total),
          }
    );
  },
  login: async (email, password) => {
    const res = await axios
      .post(`${config.baseURL}login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        console.log(res.data.data.token);
        localStorage.setItem("token", JSON.stringify(res.data.data.token));
        set({ token: res.data.data.token });
        if (res.data.data.token) {
          set({ snackbar: true, snackbar_text: "Uspešno ste se prijavili" });
          window.location.reload();
        }
      })
      .catch((err) => {
        set({ snackbar: true, snackbar_text: "Pogrešan email ili lozinka" });
      });
  },
  register: async (name, email, password, password_confirmation) => {
    const res = await axios
      .post(`${config.baseURL}register`, {
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      })
      .then((res) => {
        console.log(res.data.data.token);
        localStorage.setItem("token", JSON.stringify(res.data.data.token));
        set({ token: res.data.data.token });
        if (res.data.data.token) {
          set({ snackbar: true, snackbar_text: "Uspešno ste se prijavili" });
          window.location.reload();
        }
      })
      .catch((err) => {
        set({
          snackbar: true,
          snackbar_text: "Došlo je do greške, pokušajte ponovo kasnije.",
        });
      });
  },
  logOff: async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("cart_id");
    set({ user: {} });
    set({ token: "" });
  },
  getUser: async () => {
    const res = await fetch(`${config.baseURL}users/token`, {
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("token")?.slice(1, -1),
      },
    });

    const data = await res.json();
    set({ user: data });
    localStorage.setItem("user_id", data?.id);
    localStorage.setItem("cart_id", data?.id.toString());
    set({ cart_id: data?.id.toString() });
  },
  changeInfo: async (name, email) => {
    const res = await axios
      .patch(
        `${config.baseURL}users/${localStorage.getItem("user_id")}/profile`,
        {
          name: name,
          email: email,
        },
        {
          headers: {
            Authorization:
              `Bearer ` + localStorage.getItem("token")?.slice(1, -1),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          set({
            user: res.data.user,
          });
          set({
            snackbar: true,
            snackbar_text: "Uspešno ste promenili podatke",
          });
        } else {
          set({
            snackbar: true,
            snackbar_text: "Došlo je do greške, pokušajte ponovo",
          });
        }
      })
      .catch((err) => {
        set({
          snackbar: true,
          snackbar_text: "Došlo je do greške, pokušajte ponovo",
        });
      });
  },
  getOrders: async () => {
    const res = await fetch(
      `${config.baseURL}admin/users/${localStorage.getItem("user_id")}/orders`,
      {
        headers: {
          Authorization:
            `Bearer ` + localStorage.getItem("token")?.slice(1, -1),
        },
      }
    );

    const data = await res.json();
    console.log(data);
    set({ orders: data.data });
  },
  changePassword: async (currentPassword, newPassword, newPasswordRepeat) => {
    const res = await axios
      .patch(
        `${config.baseURL}users/${localStorage.getItem("user_id")}/password`,
        {
          old_password: currentPassword,
          password: newPassword,
          password_confirmation: newPasswordRepeat,
        },
        {
          headers: {
            Authorization:
              `Bearer ` + localStorage.getItem("token")?.slice(1, -1),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          set({
            snackbar: true,
            snackbar_text: "Uspešno ste promenili lozinku",
          });
        } else {
          set({
            snackbar: true,
            snackbar_text: "Došlo je do greške, pokušajte ponovo",
          });
        }
      })
      .catch((err) => {
        set({
          snackbar: true,
          snackbar_text: "Došlo je do greške, pokušajte ponovo",
        });
      });
  },
  removeItems: async (id, product, instance = "cart") => {
    const res = await fetch(`${config.baseURL}cart/items`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("token")?.slice(1, -1),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        instance,
        cart_id: id,
        product_id: product?.id.toString(),
      }),
    });

    const data = await res.json();

    if (!data?.data?.cart) return;
    set((state) =>
      instance === "cart"
        ? {
            items: data?.data?.cart?.items,
            total: Number(data?.data?.cart?.total),
          }
        : {
            wishes: data?.data?.cart?.items,
            total_wishlist: Number(data?.data?.cart?.total),
          }
    );
  },
  removeItem: async (id, product, quantity, instance = "cart") => {
    const res = await fetch(`${config.baseURL}cart/items`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("token")?.slice(1, -1),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        instance,
        cart_id: id,
        product_id: product?.id.toString(),
        quantity: quantity,
      }),
    });

    const data = await res.json();

    if (!data?.data?.cart) return;
    set((state) =>
      instance === "cart"
        ? {
            items: data?.data?.cart?.items,
            total: Number(data?.data?.cart?.total),
          }
        : {
            wishes: data?.data?.cart?.items,
            total_wishlist: Number(data?.data?.cart?.total),
          }
    );
  },
  setTotal: (total) => set({ total }),
  setItems: (items) => set({ items }),
}));

export default useStore;
