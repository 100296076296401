/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import api from "./api";
import "./styles.scss";
import icon from "../../assets/icons/Ellipse.png";
import SortBy from "../../components/SortBy";
import { FaBarcode, FaCartPlus } from "react-icons/fa";
import useStore from "../../store/useCart";

const ActionItem = () => {
  const { state } = useLocation();
  const [products, setProducts] = useState([]);
  const [sortData, setSortData] = useState(null);

  const handleSortBy = (data) => setSortData(data);
  useEffect(() => {
    getProducts();
  }, [state?.item, sortData]);

  const getProducts = async () => {
    const response = await api.getProducts(state?.item?.id, sortData);
    setProducts(response?.data?.data);
  };
  const { addItem, added_to_cart, cart_id, setSnackbar } = useStore();
  return (
    <div id="action-items">
      <div className="mt-5 flex justify-center">
        {state?.item?.banner ? (
          <img
            src={state?.item?.banner}
            alt="banerImg"
            className="w-[100%] max-h-[300px]"
          />
        ) : null}
      </div>
      <div className="name-wrapper">
        <hr />
        <p className="swiper_product_name flex justify-start items-center">
          <img className="mr-2" src={icon} alt={state?.item?.name} />
          {state?.item?.name}
          <img className="ml-2" src={icon} alt={state?.item?.name} />
        </p>
        <hr />
      </div>
      <SortBy haveMarginTop={false} handleSortBy={handleSortBy} />
      <div className="action-items-wrapper">
        {products.length
          ? products.map((item, index) => (
              <Link
                className={`action-item ${
                  window.innerWidth > 425 && "product_details-zoom"
                }`}
                to={`/product/${item.slug}`}
                state={{ item }}
                style={{ position: "relative" }}
              >
                <div className="rounded overflow-hidden flex flex-col items-center">
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "5px",
                      marginBottom: "5px;",
                      height: "25px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {item?.sticker ? (
                      <img
                        className="h-[40px]"
                        style={{ zIndex: 2, position: "absolute", top: "15px" }}
                        src={item && item.sticker}
                        alt="nema slike"
                      />
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "180px",
                    }}
                  >
                    <div
                      style={{
                        background: `url(${item && item.image})`,
                        height: "100%",
                        backgroundPosition: "center !important",
                        backgroundSize: "contain !important",
                        width: "200px",
                        backgroundRepeat: "no-repeat !important",
                      }}
                      className="max-h-[180px] image-as-background "
                      src={item && item.image}
                      alt="nema slike"
                    />
                  </div>
                  <div className=" p-4 w-[100%]">
                    <p className="product_name">{item?.name}</p>
                    <p
                      className="info_values flex content-center	items-center"
                      style={{ color: "#777676" }}
                    >
                      {window.innerWidth > 420 ? (
                        <FaBarcode style={{ marginRight: "10px" }} />
                      ) : null}
                      {item?.barcode}
                    </p>{" "}
                    <p className="info_values">
                      Neto masa {item?.mass} {item?.massunitname}
                    </p>
                    <p className="info_values">
                      {item?.unit_price} RSD po {item?.massunitname}
                    </p>
                    <p className="info_values">{item?.webprice}</p>{" "}
                    <p className="price">{item.price} RSD</p>
                    {item?.sticker && item?.web_price ? (
                      <p className="">
                        Stara cena:{" "}
                        {item?.web_price
                          ? parseFloat(item.web_price).toFixed(2)
                          : null}{" "}
                        RSD
                      </p>
                    ) : null}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSnackbar("Proizvod je dodat u korpu");
                        addItem(cart_id, item, 1);
                      }}
                      className="add_to_cart"
                    >
                      <FaCartPlus />
                      Dodaj u korpu
                    </button>
                    <div
                      style={{
                        width: "100%",
                        marginRight: "5px",
                        marginBottom: "5px;",
                        height: "25px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {item?.bottom_sticker ? (
                        <img
                          className="w-[100%]"
                          src={item && item.bottom_sticker}
                          alt="nema slike"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </Link>
            ))
          : null}
      </div>
    </div>
  );
};

export default ActionItem;
