import { BsX } from "react-icons/bs";
import useStore from "../../store/useCart";
import OrderForm from "./OrderForm";
import { FaList, FaShoppingCart } from "react-icons/fa";

export default function Wishlist() {
  const { removeItems, removeItem, cart_id, addItem, wishes, setSnackbar } =
    useStore();

  console.log(wishes, "wishes");

  return wishes?.length ? (
    <section className="w-full grid grid-cols-1 lg:grid-cols-2 py-20">
      <div className="w-full max-h-[500px] lg:max-h-[700px] overflow-y-scroll flex flex-col gap-4">
        {wishes?.map((item) => {
          return (
            <a
              href={"/product/" + item.product.slug}
              className="grid grid-cols-5 relative border-b"
            >
              <BsX
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeItems(cart_id, item.product, "wishlist");
                }}
                className="absolute right-0 top-0  w-7 h-7 text-gray-400 cursor-pointer"
              />
              <div>
                <img
                  className="w-full aspect-[1] object-contain p-1"
                  src={item.product.image}
                  alt={item.product.name}
                />
              </div>
              <div className="col-span-2 line-clamp-2  flex  flex-col justify-center">
                {item.product.name}
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className=" h-10 w-[150px]"
                >
                  <div
                    onClick={() => {
                      removeItems(cart_id, item.product, "wishlist");
                      addItem(cart_id, item.product, 1, "cart");
                      setSnackbar("Proizvod prebačen u korpu");
                    }}
                    className=" flex justify-center bg-neutral-700 text-white px-5 py-1 rounded-md text-nowrap items-center border"
                  >
                    Prebaci u korpu
                  </div>
                </div>
              </div>
              <p className="text-[#a6ce38] text-xl  justify-end  pr-5 col-span-2 flex items-center font-bold text-nowrap tracking-wide">
                {parseFloat(item.quantity * item.product.price).toFixed(2)} RSD
              </p>
            </a>
          );
        })}
      </div>
      <div className="w-full aspect-video flex justify-center items-center">
        <div className="flex text-lg justify-center items-center gap-5 flex-row">
          <a
            href="/"
            className="flex px-5 bg-neutral-200 py-2 rounded-md items-center gap-2"
          >
            <FaList />
            <span className="">Nastavi sa kupovinom</span>
          </a>
          <a
            href="/korpa"
            className="flex px-5 bg-black text-white py-2 rounded-md items-center gap-2"
          >
            <FaShoppingCart className="" />
            <span className="">Idi u korpu</span>
          </a>
        </div>
      </div>
    </section>
  ) : (
    <div className="w-full min-h-[70vh] flex flex-col gap-10 justify-center items-center">
      <FaList className="text-9xl text-[#a6ce38]" />
      <p className="text-4xl text-[#a6ce38]">Lista je prazna</p>
    </div>
  );
}
