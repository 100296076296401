/* eslint-disable react-hooks/exhaustive-deps */
import { ClickAwayListener } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import api from "./api";
import "./Search.scss";

const Search = ({ placeholder, handleShow }) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setValue("");
    setData([]);
    setFilteredData([]);
    setOpen(false);
  }, [window.location.pathname]);

  const getApi = async () => {
    const response = await fetch(`https://api.astrgovina.rs/api/products`);
    const dataResponse = await response.json();
    setData(dataResponse.data);
  };

  useEffect(() => {
    getApi();
  }, []);

  useEffect(() => {
    if (filteredData.length) setOpen(true);
  }, [filteredData.length]);

  const handleFilter = async (event) => {
    const searchWord = event.target.value;
    setValue(searchWord);
    const response = await api.searchProduct(searchWord);
    setFilteredData(response?.data?.data);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="search_wrapper">
        <GiHamburgerMenu
          className="blok md:hidden"
          onClick={() => {
            setOpen(true);
            handleShow(true);
          }}
        />
        <div className="input_field">
          <input
            onFocus={() => !open && setOpen(true)}
            className="w-100"
            onKeyDown={(e) => {
              if (e.key === "Enter" && value) {
                window.location.href = `/pretraga/${value}`;
              }
            }}
            onChange={handleFilter}
            type="text"
            value={value}
            placeholder="Pretrazite proizvod..."
          />
          <BsSearch
            onClick={() => {
              if (value) window.location.href = `/pretraga/${value}`;
            }}
          />
        </div>

        <>
          {filteredData.length && open ? (
            <div className="input_results">
              {filteredData.map((value, index) => {
                return (
                  <Link
                    key={index}
                    onClick={() => setOpen(false)}
                    to={`/product/${value.slug}`}
                    state={{ item: value }}
                    className={`searchDetails ${
                      window.innerWidth > 425 && "product_details-zoom"
                    }`}
                  >
                    <div className="item-wrapper">
                      <div className="flex search-item">
                        <div className="search_img ">
                          <div
                            id="img"
                            style={{ background: `url(${value.image})` }}
                            alt={value.name}
                          />
                        </div>
                        <div className="search_details ml-14">
                          <p className="product_name">{value.name}</p>
                          <p className="price">
                            {parseFloat(value.price).toFixed(2)} RSD
                          </p>
                          <div
                            className="flex"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <span>Prikaži više</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : null}
        </>
      </div>
    </ClickAwayListener>
  );
};

export default Search;
