/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./Category.scss";
import logo from "../../assets/icons/as-logo.png";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { FaBarcode, FaCartPlus } from "react-icons/fa";
import useStore from "../../store/useCart";
// import SortBy from "../../components/SortBy";

const CategoryPage = () => {
  const { id } = useParams();
  const [data, setData] = useState("");
  const [dataCategories, setDataCategories] = useState("");
  const { state } = useLocation();
  // const [sortData, setSortData] = useState(null);
  // const handleSortBy = (data) => setSortData(data);

  const apiCall = async () => {
    let url = `https://api.astrgovina.rs/api/categories/${id}`;

    const response = await fetch(url);
    const dataResponse = await response.json();
    setData(dataResponse.data);
  };

  useEffect(() => {
    apiCall();
  }, [id]);
  const apiCallCategories = async () => {
    let url = `https://api.astrgovina.rs/api/category-products/${id}`;

    const response = await fetch(url);
    const dataResponse = await response.json();
    setDataCategories(dataResponse.data);
  };

  const { addItem, added_to_cart, cart_id, setSnackbar } = useStore();

  useEffect(() => {
    apiCallCategories();
  }, [id /*, sortData*/]);
  return (
    <>
      <Breadcrumbs style={{ marginTop: "15px" }} aria-label="breadcrumb">
        <Typography color="text.primary">{state?.category}</Typography>
      </Breadcrumbs>
      <div className="grid grid-cols-5 categories-page  mt-10">
        {data &&
          data.subcategories.map((item, index) => {
            return (
              <div className="categories-item-size px-3" key={index}>
                <Link
                  to={`/category-products/${item.id}`}
                  state={{
                    category: state?.category,
                    categoryid: state?.categoryid,
                    subcategory: item?.name,
                  }}
                  className="flex flex-col justify-center items-center"
                >
                  <div className="category_product flex justify-center items-center min-w-[100%]">
                    <p>{item?.name}</p>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
      {/* <SortBy handleSortBy={handleSortBy} /> */}

      <div className="mt-10 grid grid-cols-5 category-products-page">
        {dataCategories &&
          dataCategories.map((item, index) => {
            return (
              <Link
                to={`/product/${item.slug}`}
                className={`product_details ${
                  window.innerWidth > 425 && "product_details-zoom"
                } mt-10`}
                key={index}
                state={{
                  item,
                  breadcrumb: {
                    category: state?.category,
                    categoryid: state?.categoryid,
                  },
                }}
              >
                <div className="rounded overflow-hidden flex flex-col items-center">
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "5px",
                      marginBottom: "5px;",
                      height: "25px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {item?.sticker ? (
                      <img
                        className="h-[40px]"
                        style={{ zIndex: 2, position: "absolute", top: "15px" }}
                        src={item && item.sticker}
                        alt="nema slike"
                      />
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "180px",
                    }}
                  >
                    <div
                      style={{
                        background: `url(${item && item.image})`,
                        height: "100%",
                        backgroundPosition: "center !important",
                        backgroundSize: "contain !important",
                        width: "200px",
                        backgroundRepeat: "no-repeat !important",
                      }}
                      className="max-h-[180px] swiper-item-size"
                      src={item && item.image}
                      alt="nema slike"
                    />
                  </div>{" "}
                  <div className=" p-4 w-[100%]">
                    <p className="product_name">{item.name}</p>
                    <p
                      className="info_values flex content-center	items-center"
                      style={{ color: "#777676" }}
                    >
                      {window.innerWidth > 420 ? (
                        <FaBarcode style={{ marginRight: "10px" }} />
                      ) : null}
                      {item?.barcode}
                    </p>{" "}
                    <p className="info_values">
                      Neto masa {item?.mass} {item?.massunitname}
                    </p>
                    <p className="info_values">
                      {item?.unit_price} RSD po {item?.massunitname}
                    </p>
                    <p className="info_values">{item?.webprice}</p>{" "}
                    <p className="price">{item.price} RSD</p>
                    {item?.sticker && item?.web_price ? (
                      <p className="">
                        Stara cena:{" "}
                        {item?.web_price
                          ? parseFloat(item.web_price).toFixed(2)
                          : null}{" "}
                        RSD
                      </p>
                    ) : null}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSnackbar("Proizvod je dodat u korpu");
                        addItem(cart_id, item, 1);
                      }}
                      className="add_to_cart"
                    >
                      <FaCartPlus />
                      Dodaj u korpu
                    </button>
                    {/* <p className="save_money">
                      Ušteda: {(item.recommended_price - item.price).toFixed(2)}{" "}
                      RSD
                    </p> */}
                    <div
                      style={{
                        width: "100%",
                        marginRight: "5px",
                        marginBottom: "5px;",
                        height: "25px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {item?.bottom_sticker ? (
                        <img
                          className="w-[100%]"
                          src={item && item.bottom_sticker}
                          alt="nema slike"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </>
  );
};

export default CategoryPage;
